export { PrismaClient, Prisma as PrismaTypes } from "./prismaGenerated_DO_NOT_TOUCH"

import {
  BrowserVideoClientStateSession,
  ExternalIntegration,
  File,
  ObjectTemplate,
  ObjectVariant,
  ObjectVariantSpritesheet,
  ObjectVariantSpritesheetAnimation,
  Prisma,
  Recording,
  RecordingExtraBudgets,
  Sound,
  Space,
  SpaceCapacityWarning,
  SpaceCapacityWarningUser,
  SpaceExtension,
  SpaceSSOSettings,
  SpaceTemplate,
  SpaceUser,
  SpaceUserDesk,
  SpaceUserOnboarding,
  SpaceUserOnboardingChecklist,
  SpaceUserSetting,
  SpawnToken,
  User,
  UserDeletionForStaging,
  UserOutfit,
  UserOutfitTemplate,
  Wearable,
  WearablePart,
  WearableV2,
} from "./prismaGenerated_DO_NOT_TOUCH"

/**
 * This file solves two issues:
 * 1. Auto-importing types from prisma doesn't work on our version of TS, and possibly not at all
 *    on any version. https://github.com/prisma/prisma/issues/14392
 * 2. Sometimes the schema version of the types is different from our manually-maintained version
 *    of the types. Adding "*Prisma" suffix helps disambiguate them until we can align better.
 */

export type SpaceCapacityWarningPrisma = SpaceCapacityWarning
export type SpaceCapacityWarningUserPrisma = SpaceCapacityWarningUser
export type SpaceExtensionPrisma = SpaceExtension
export type WearablePrisma = Wearable
export type WearableV2Prisma = WearableV2
export type WearablePartPrisma = WearablePart
export type WearableCreateInput = Prisma.WearableCreateInput
export type WearablePartCreateInput = Prisma.WearablePartCreateInput
export type WearableWithPartsPrisma = Prisma.WearableGetPayload<{ include: { parts: true } }>
export type WearableCreateInputWithWearablePartsCreateInputSimplified = WearableCreateInput & {
  parts: Prisma.WearablePartCreateWithoutWearableInput[]
}
export type ExternalIntegrationPrisma = ExternalIntegration
export type SpawnTokenPrisma = SpawnToken
export type ObjectTemplatePrisma = ObjectTemplate
export type ObjectVariantPrisma = ObjectVariant
export type SoundPrisma = Sound
export type FilePrisma = File
export type RecordingPrisma = Recording
export type RecordingCreateInput = Prisma.RecordingCreateInput
export type SpaceUserOnboardingPrisma = SpaceUserOnboarding
export type SpaceUserOnboardingChecklistPrisma = SpaceUserOnboardingChecklist
export type UserPrisma = User
export type UserOutfitPrisma = UserOutfit
export type UserDeletionForStagingPrisma = UserDeletionForStaging
export type SpaceUserPrisma = SpaceUser
export type SpaceUserDeskPrisma = SpaceUserDesk
export type SpaceUserSettingPrisma = SpaceUserSetting
export type SpaceUserCreateInput =
  | Prisma.SpaceUserCreateInput
  | Prisma.SpaceUserUncheckedCreateInput
export type SpaceUserUpdateInput = Prisma.SpaceUserUpdateInput
export type SpacePrisma = Space
export type RecordingExtraBudgetsPrisma = RecordingExtraBudgets

/**
 * Exported enums
 * ===============
 *
 * You need to export as a const, otherwise you can't access it as a value.
 */

export type {
  ApiKey as ApiKeyPrisma,
  BrowserVideoClientStateSession,
  FloorV2,
  GoogleToken as GoogleTokenPrisma,
  GroupUserV2,
  GroupV2,
  InviteToken as InviteTokenPrisma,
  LegacyInviteToken as LegacyInviteTokenPrisma,
  ObjectTemplateCategory as ObjectTemplateCategoryPrisma,
  RoleV2,
  SpaceRolePermissionOverride,
  SpaceSSOEmailDomain,
  SpaceSSOLinkedSpace,
  SpaceUserRequest,
  SpaceUserV2,
  SpaceV2,
  SpaceV2RoleAssignment,
  SpaceWorkOSConnection,
  SurveyResponse,
  UserDevice,
  UserFeedback,
} from "./prismaGenerated_DO_NOT_TOUCH"
export {
  AdminRoleType as AdminRoleTypePrisma,
  DeviceType,
  ExtensionType,
  ExternalIntegrationType,
  GuestPassStatus as GuestPassStatusPrisma,
  MeetingParticipantInviteStatus,
  MeetingParticipantResponseStatus,
  MeetingType,
  MeetingVisibility,
  MemberOnboardingDesk,
  MemberOnboardingStep,
  Permission,
  Prisma,
  RoleV2Type as RoleV2TypePrisma,
  SpaceChat,
  SpacePlanOverride,
  SpaceRole as SpaceRolePrisma,
  SpaceSSOConnectionState,
  SpaceSSOEmailDomainVerificationState,
  SpaceTemplateArea,
  SpaceTemplateSize,
  SpaceTemplateSubtab,
  SpaceTemplateTab,
  SpaceUserRequestStatus,
  SpaceUserRequestStatusChangeSource,
  SpaceUserStatus,
  SpaceUserStatusEnd,
  SpaceUserStatusEnd as SpaceUserStatusEndPrisma,
  SurveyResponseType,
  UseCase,
  UserOutfitTemplateType,
  WearableLayer,
  WearableSubType as WearableSubTypePrisma,
  WearableType as WearableTypePrisma,
  WearableTypeV2 as WearableTypeV2Prisma,
} from "./prismaGenerated_DO_NOT_TOUCH"

// Subset of BrowserVideoClientStateSession
export type BrowserVideoClientStateSessionIndex = Pick<
  BrowserVideoClientStateSession,
  | "id"
  | "avStrategy"
  | "commitHash"
  | "buildTimestamp"
  | "createdAt"
  | "electron"
  | "message"
  | "playerId"
  | "spaceId"
  | "updatedAt"
  | "userAgentBrowser"
  | "userAgentOs"
  | "userAgentVersion"
  | "sessionId"
  | "liveKitSessionId"
  | "liveKitParticipantId"
  | "agoraVideoId"
  | "agoraScreenId"
  | "agoraVideoChannelName"
  | "agoraScreenChannelName"
>

// When we export SpawnTokenType from Prisma, we see the following error:
// error TS2702: 'SpawnTokenType' only refers to a type, but is being used as a namespace here
// We should fix this once we figure out how to get rid of this error [OCTO-200]
export enum SpawnTokenType {
  SpawnTile = "SpawnTile",
  Nook = "Nook",
  Desk = "Desk",
  DefaultSpawnTile = "DefaultSpawnTile",
}
export type SpaceUserSettingPinnedUser = {
  pinnedUserId: string
}

/**
 * We also need to re-export the raw model types, otherwise we run into infamous "inferred type
 * cannot be named" TS errors in some places:
 * https://gather-town.slack.com/archives/C03CQTHPKH8/p1659541105444499
 * If you get this error, import the type below, not the one above, and rename as `MyModelPrisma`.
 */
export type {
  ExternalIntegration,
  ObjectTemplate,
  ObjectVariant,
  ObjectVariantSpritesheet,
  ObjectVariantSpritesheetAnimation,
  SpaceSSOSettings,
  SpaceTemplate,
  SpawnToken,
  UserOutfitTemplate,
  Wearable,
  WearablePart,
}
