import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { asUuid } from "./stringHelpers"

export const directionMap = {
  stand: 0,
  "down-1": 1,
  "down-2": 2,
  "up-1": 3,
  "up-2": 4,
  "left-1": 5,
  "left-2": 6,
  "right-1": 7,
  "right-2": 8,
  "dance-1": 9,
  "dance-2": 10,
  "dance-3": 11,
  "dance-4": 12,
}

export const SPEED = 100
export const MODIFIER = 100
export const STEP_INTERVAL = 10
export const TELEPORT_INTERVAL = 50
export const VIDEO_THRESHOLD = 12
export const FILTER_THRESHOLD = 16
export const DEFAULT_OBJECT_DISTANCE = 3
export const objectSizes = 32

export const PRIVATE_AREA_COLORS = [
  "#d32f2f",
  "#c2185b",
  "#7b1fa2",
  "#512da8",
  "#303f9f",
  "#1976d2",
  "#0288d1",
  "#0097a7",
  "#00796b",
  "#388e3c",
  "#689f38",
  "#afb42b",
  "#ffd835",
  "#ffb300",
  "#fb8c00",
  "#f4511e",
] as const

// eventually we probably want to migrate this to a firebase collection?
// export const assets = {
//   CHAIR: 1,
// };
//

export const WHISPER_COLORS = new Set<string>([
  "#FF9DDE",
  "#FBD54E",
  "#64E9D1",
  "#AEE964",
  "#A966FF",
  "#FC7079",
  "#FF9960",
  "#EA36AC",
  "#B67800",
  "#2D62EC",
  "#37C214",
  "#7230FF",
  "#D42E2E",
  "#E85110",
])

export enum InviteLinkExpirationTimes {
  ONE_MONTH = "1 month",
  SEVEN_DAYS = "7 days",
  ONE_DAY = "1 day",
  TWELVE_HOURS = "12 hours",
  SIX_HOURS = "6 hours",
  ONE_HOUR = "1 hour",
  NEVER = "Never",
}

export const MS_PER_HOUR = 60 * 60 * 1000
export const INVITE_LINK_EXPIRATION_TIME: { [key in InviteLinkExpirationTimes]: number } = {
  [InviteLinkExpirationTimes.ONE_HOUR]: MS_PER_HOUR,
  [InviteLinkExpirationTimes.SIX_HOURS]: 6 * MS_PER_HOUR,
  [InviteLinkExpirationTimes.TWELVE_HOURS]: 12 * MS_PER_HOUR,
  [InviteLinkExpirationTimes.ONE_DAY]: 24 * MS_PER_HOUR,
  [InviteLinkExpirationTimes.SEVEN_DAYS]: 7 * 24 * MS_PER_HOUR,
  [InviteLinkExpirationTimes.ONE_MONTH]: 30 * 24 * MS_PER_HOUR,
  Never: Infinity,
}

export const MAX_NAME_LENGTH = 50
export const MAX_SHORT_LENGTH = 100
export const MAX_MEDIUM_LENGTH = 500
export const MAX_LONG_LENGTH = 2000
export const MAX_SPACE_NAME_LENGTH = 25

export const GATHER_EMAIL_DOMAIN = "@gather.town"

export const MS_PER_DAY = MS_PER_HOUR * 24

export const COMPUTER_THROW_IMAGE = "/images/game/computer-throw.png"
export const NOTE_THROW_IMAGE = "/images/game/note-throw.png"
export const STARS_IMAGE = "/images/game/stars.png"
export const THREE_STARS_IMAGE = "/images/game/three-stars.png"
export const STARS_IMAGE_MOBILE = "/images/game/stars-mobile.png"
export const METAVERSE_ELLIPSE = "/images/site/metaverse-ellipse.png"
export const METAVERSE_ELLIPSE_MOBILE = "/images/site/metaverse-ellipse-mobile.png"

export const MAX_DESK_DESCRIPTION_LENGTH = 500

// from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
export const VALID_EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
export const VALID_EMAIL_DOMAIN_PATTERN =
  /^(?=.{4,255}$)([a-zA-Z0-9_]([a-zA-Z0-9_-]{0,61}[a-zA-Z0-9_])?\.){1,126}[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/
export const MAX_INVITE_EMAILS_TO_SEND = 500
export const MAX_EMAIL_DOMAINS_TO_ALLOW = 100
export const VALID_SPACE_NAME_PATTERN = /^(?!\s*$)^[a-zA-Z0-9_-\s]+$/

export const validUserIDStartersForEmail: string[] = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "e",
  "2",
  "m",
  "R",
  "T",
]

/**
 * @deprecated Please use TutorialTask generated by Prisma!
 */
export enum TutorialTaskIds {
  // New RW Onboarding V2
  RW_ONBOARDING_V2_INTRO = "RW_ONBOARDING_V2_INTRO",
  RW_ONBOARDING_V2_CHOOSE = "RW_ONBOARDING_V2_CHOOSE",
  RW_ONBOARDING_V2_FOLLOW_TO_DESKS = "RW_ONBOARDING_V2_FOLLOW_TO_DESKS",
  RW_ONBOARDING_V2_FOLLOW_TO_MEETINGS = "RW_ONBOARDING_V2_FOLLOW_TO_MEETINGS",
  RW_ONBOARDING_V2_MEETINGS = "RW_ONBOARDING_V2_MEETINGS",
}

export enum IframeErrors {
  NONE = "none",
  INVALID_URL = "invalid url",
  IFRAME_FORBIDDEN = "iframe forbidden",
  BAD_RESPONSE = "bad response",
  HTTP = "http",
  OTHER = "other",
  NO_URL = "no url",
}

export const IFRAME_WHITELIST_PREFIXES = ["https://docs.google.com/"]

export enum Orientation {
  Down,
  Right,
  Up,
  Left,
}

export enum Platform {
  Web = "web",
  DesktopApp = "desktop app",
  MobileiOS = "mobile ios",
  MobileAndroid = "mobile android",
}

export type Social = "Facebook" | "LinkedIn" | "Twitter"

export const GOKART_URL =
  "https://cdn.gather.town/storage.googleapis.com/v0/b/gather-town.appspot.com/o/manually-uploaded%2Fgokarts%2Fgokart-spritesheet-august-2023.png?alt=media&token=8b6bc4dd-d1f3-4176-ad06-293b57efcadf"

// TODO [rebuild]: update for GatherOffice when it exists or if this changes
export const GATHER_OFFICE_ID: Uuid = asUuid("f533840b-dea7-4b58-a867-c46c4152b97d")

export const FORCE_STAGING_SPACES = [
  GATHER_OFFICE_ID,
  "49pW4GbwVoFECNAM\\livekit",
  "XM1QP7KknX5YqRzS\\gather-load-test-alasdair",
  "bQFQ4gBWQW4i8lkr\\gather-test-asuarez", // Added to test audio processing performance
  "f1601f82-ab0d-4cce-bf47-f250fe3219c1", // Added by Virtual World team to test nametags
]

export const GATHER_LINK_SERVICE_PORT = 48563
export const GATHER_LINK_SERVICE_PORT_DEV = 42413

export const SPRITESHEET_DELIMITER = "."
export const TERMS_OF_SERVICE_LINK = "https://www.gather.town/terms-of-service"
export const CONTACT_US_PATH = "/contact"
export const CONTACT_US_LINK = `https://www.gather.town${CONTACT_US_PATH}`

export const GIFT_TEMPLATE_ID = "Note - sCd4usANUKyXQ1ZW2yxzt"
export const GIFT_NORMALS = {
  sticky:
    "https://cdn.gather.town/v0/b/gather-town.appspot.com/o/remote-work%2Fgifts%2Fsticky_normal.png?alt=media&token=144a261a-7a60-4a83-9fc8-bfa8194fb5ae",
  balloon:
    "https://cdn.gather.town/v0/b/gather-town.appspot.com/o/remote-work%2Fgifts%2Fballoon_normal.png?alt=media&token=89a48b6d-9403-4436-a331-3c91c73b9e9d",
  greeting:
    "https://cdn.gather.town/v0/b/gather-town.appspot.com/o/remote-work%2Fgifts%2Fheart_sticky_normal.png?alt=media&token=9bea8c36-679b-4cd0-b321-f707a61eb0e9",
  cake: "https://cdn.gather.town/v0/b/gather-town.appspot.com/o/remote-work%2Fgifts%2Fcake_normal.png?alt=media&token=742580d4-185b-4f0d-838a-4e0e77d7419e",
  cakeslice:
    "https://cdn.gather.town/v0/b/gather-town.appspot.com/o/remote-work%2Fgifts%2Fcake_slice_normal.png?alt=media&token=d18046d7-d94d-47d8-a36c-35c2439798cb",
  champagne:
    "https://cdn.gather.town/v0/b/gather-town.appspot.com/o/remote-work%2Fgifts%2Fchampagne_normal.png?alt=media&token=56ed1bcd-fc1c-4eaa-b7ab-66b46aac7445",
}

export enum ClientType {
  Default = "default",
  Recorder = "recorder",
}

export enum ChannelType {
  Everyone = "Everyone",
  Nook = "Nook",
  DM = "DM",
  CanonicalDM = "CanonicalDM",
}

export enum ChatActionType {
  MESSAGE = 0,
  ATTACHMENT = 1,
  EDIT = 2,
  DELETE = 3,
  REACT = 4,
}

export interface ChatEvent {
  channelUrl: string
  channelType: ChannelType
  actionType: ChatActionType
  messageId: string
  content: string
  mentionedUserIds: Uuid[]
  channelMemberUserIds: Uuid[]
}

export const isDmChannelType = (type: string): boolean =>
  type === ChannelType.DM || type === ChannelType.CanonicalDM

export const isOfficeChannelType = (type: string): boolean =>
  type === ChannelType.Everyone || type === ChannelType.Nook

export enum CustomMessageType {
  GiftReaction = "GiftReaction",
  ChannelInvitationSucceeded = "ChannelInvitationSucceeded",
  Tombstone = "Tombstone",
  NookChannelUserEvent = "NookChannelUserEvent",
}

export type ChannelInvitationMetadata = {
  inviterSpaceUserUuid: string
  inviteeSpaceUserUuids: string[]
}

export enum NookChannelUserEvent {
  USER_LEFT,
  USER_JOINED,
}

export type NookChannelUserEventMetadata = {
  event: NookChannelUserEvent
  userIds: string[]
}

// https://sendbird.com/docs/chat/platform-api/v3/message/message-overview#4-comparing-message-types
export enum SendbirdMessageType {
  Text = "MESG",
  File = "FILE",
  Admin = "ADMM",
}
