// TODO [Rebuild] [Experimentation]: Remove

import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

type SpaceV2 = {
  id: string
}

const c = initContract()

export const spacesV2 = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.ExperimentalSpacesV2,
    body: z.object({
      // TEMPORARY: Allowing specifying a specific space id as a shim.
      // This allows us to create a v2 space with the same id as a v1 space,
      // while we're in the world of supporting v1/v2 spaces (very ephemerally)
      id: z.string().optional(),

      // Non-ephemeral params:
      name: z.string(),
    }),
    responses: { 200: c.type<SpaceV2>() },
  },
})
