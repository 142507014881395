import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const meetings = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceMeetings,
    body: z.object({
      areaId: z.string().uuid(),
    }),
    responses: {
      200: c.type<{
        id: string
        areaId?: string
      }>(),
      400: c.type<{ error: string }>(),
    },
  },
  update: {
    method: RequestMethod.PUT,
    path: HttpV2Paths.SpaceMeetingV2,
    body: z.object({
      areaId: z.string().uuid(),
    }),
    responses: {
      200: c.type<{
        id: string
        areaId?: string
      }>(),
      404: c.type<{ error: string }>(),
    },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceMeetingV2,
    responses: {
      200: c.type<{
        id: string
        areaId?: string
      }>(),
      404: c.type<{ error: string }>(),
    },
  },
})
